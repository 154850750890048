<template>
  <section class="container my-50 lg:my-100">
    <div
      v-if="hasVideo"
      class="aspect-video bg-primary-200 relative overflow-hidden"
    >
      <video
        ref="video"
        muted
        playsinline
        autoplay
        loop
        :src="videoEmbed[0].embeddedAsset?.url"
        class="size-full object-cover"
        @play="isPlaying = true"
        @pause="isPlaying = false"
      />
      <button
        class="absolute z-10 right-0 bottom-0 text-white hover:text-primary-200 transition-colors p-25"
        type="button"
        aria-label="Play / pause video"
        @click="$refs.video.paused ? $refs.video.play() : $refs.video.pause()"
      >
        <playIcon :class="['w-35', { hidden: isPlaying }]" />
        <pauseIcon :class="['w-35', { hidden: !isPlaying }]" />
      </button>
    </div>

    <div class="bg-primary-100 h-full flex flex-wrap items-center text-center md:text-left">
      <div class="w-full md:w-1/2">
        <img
          v-if="image.length"
          :src="image[0].url"
          :srcset="image[0].srcset"
          sizes="(max-width: 600px) 100px, 100vw"
          width="800"
          height="800"
          class="w-full"
          :alt="image[0].title"
          loading="lazy"
        >
      </div>
      <div class="w-full md:w-1/2 p-25 lg:p-50">
        <h4
          class="wysiwyg-title text-3xl lg:text-5xl"
          v-html="heading"
        />
        <div
          v-if="intro"
          class="mt-25 wysiwyg"
          v-html="intro"
        />
        <NuxtLink
          v-if="button?.url"
          :to="button?.url"
          :target="button?.target"
          class="inline-flex items-center text-xl font-serif underline space-x-5 group mt-25"
          external
        >
          <span>{{ button?.text }}</span>
          <chevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from '#imports';

// General icons
import chevronIcon from '@/assets/img/icon-chevron.svg';
import playIcon from '@/assets/img/icon-play.svg';
import pauseIcon from '@/assets/img/icon-pause.svg';

// Props
const props = defineProps({
  image: {
    type: Array,
    required: false,
    default: () => ([]),
  },
  heading: {
    type: String,
    required: true,
  },
  intro: {
    type: String,
    required: false,
    default: '',
  },
  button: {
    type: Object,
    required: false,
    default: null,
  },
  videoEmbed: {
    type: Array,
    default: () => [],
  },
});

const video = ref(null);
const isPlaying = ref(false);
const hasVideo = props.videoEmbed.length && props.videoEmbed[0].embeddedAsset?.type;

onMounted(() => {
  if (hasVideo) isPlaying.value = !video.value.paused;
});
</script>
